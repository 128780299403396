@font-face {
  font-family: "Roboto_slab";
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilda_display";
  src: local("Gilda_display"),
    url(./fonts/Gilda_display/GildaDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto_slab";
  font-weight: 900;
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto_slab";
  font-weight: 900;
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Black.ttf) format("truetype");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.name {
  font-family: Roboto_slab, "Times New Roman", Times, serif;
  font-weight: 500;
  font-size: larger;
}

.head {
  font-family: Roboto_slab, "Times New Roman", Times, serif;
}

.nav {
  font-family: Gilda_display, Arial, serif;
  font-weight: 700;
}

.para {
  font-family: Gilda_display, Arial, serif;
  /* font-weight: 700; */
}

.back {
  background-color: #0f0f0f;
  background-attachment: fixed;
}

.cursor {
  position: fixed;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: white;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}